import { render, staticRenderFns } from "./rankingList.vue?vue&type=template&id=98ac69ba&scoped=true&"
import script from "./rankingList.vue?vue&type=script&lang=js&"
export * from "./rankingList.vue?vue&type=script&lang=js&"
import style0 from "./rankingList.vue?vue&type=style&index=0&id=98ac69ba&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98ac69ba",
  null
  
)

export default component.exports