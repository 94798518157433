<template>
    <div class="home2">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 头部大图片 -->
        <div class="headImg">
            <div class="zw"></div>
            <div class="cont">
                <div class="title">
                    <div class="name">工业品榜单</div>
                    <div class="btn">专注于优秀的设计方案 为企业助一臂之力！</div>
                </div>
            </div>
            <div class="zw"></div>

            <!-- tabs -->
            <div class="tabs"  v-loading="loading">
                <div class="tab" v-if=" tabsList.length > 0" :style="{ width: tabsList.length * 146 + 'px' }">
                    <div class="son" :class="[i.act ? 'tabsAct' : '']" @click="tabCilck(index)"
                        v-for="(i, index) in tabsList" :key="index">{{ i.typeName }}</div>
                </div>
            </div>

            <div class="top60"  v-loading="loading">
                <div class="topTitle" v-if="tabsList.length > 0">
                    <img class="img1" src="@/assets/hw1.png" alt="">
                    <div class="title">{{tabsList.find(obj => obj.act).typeName}}TOP榜单</div>
                    <img class="img2" src="@/assets/hw2.png" alt="">
                </div>
                <div class="list" v-if="tabsList.length > 0">
                    <div class="son cur" @click="spuClick(it.spuId)" v-for="(it, index) in tabsList.find(obj => obj.act).newArr" :key="index">
                        <div class="img">
                            <img v-if="it.img || it.mainImage" :src="(it.img || it.mainImage).split(',')[0]" alt="">
                            <img v-else src="@/assets/zwtp.png" alt="">
                        </div>
                        <div class="r">
                            <div class="name">{{ it.title }}</div>
                            <div class="jg">
                                <div class="jiage" v-if="it.price == 0">￥ <span>面议</span></div>
                                <div class="jiage" v-else>￥ <span>{{ it.price }}</span></div>
                                <!-- <div class="yishou">已售{{it.sales}}{{ it.unit }}</div> -->
                            </div>
                        </div>
                        <div class="pmbq">
                            <img v-if="index == 0" src="@/assets/pm1.png" alt="">
                            <img v-if="index == 1" src="@/assets/pm2.png" alt="">
                            <img v-if="index == 2" src="@/assets/pm3.png" alt="">
                            <img v-if="index > 2" src="@/assets/pm4.png" alt="">
                            <div v-if="index > 2" >{{ index + 1 }}</div>
                        </div>
                    </div>

                    <!-- 分页 -->
                    <!-- <div class="paging">
                        <el-pagination background layout="prev, pager, next" :total="100">
                        </el-pagination>
                    </div> -->
                    <!-- 底部 -->
                    <!-- <bottomContent></bottomContent> -->
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import { getRankingList } from '@/utils/api/homeApi/index'
export default {
    name: 'rankingList',
    components: {
        largeHead,
        bottomContent
    },
    data() {
        return {
            loading:true,
            // 头部激活菜单
            tabArrShow: 8,
            tabsList: [
                {
                    name: '复合加工中心',
                    act: true
                },
                {
                    name: '卧式加工中心',
                    act: false
                },
                {
                    name: '其他加工中心',
                    act: false
                },
                {
                    name: '龙门加工中心',
                    act: false
                },
                {
                    name: '加工中心光机',
                    act: false
                },
                {
                    name: '钻攻中心',
                    act: false
                },
                {
                    name: '立式加工中心',
                    act: false
                },
            ],
        }
    },
    mounted() {
        // 获取榜单数据
        this.getRanking()
    },
    methods: {
        tabCilck(index) {
            this.tabsList.map((t) => (t.act = false));
            this.tabsList[index].act = true;
        },
        // 榜单
        async getRanking() {
            const res = await getRankingList()
            if (res.statusCode == 8201) {
                this.tabsList = res.data
                this.tabsList.map(d => {
                    this.$set(d, 'act', false)
                })
                this.tabsList[0].act = true
                this.tabsList.map(b => {
                    console.log(b)
                    let newArr = []
                    b.children.map(t => t.children.map(g => g.mallSpus?.map(e => {
                        newArr.push(e)
                    })))
                    this.$set(b,'newArr',newArr)
                })
                this.loading =false
                console.log(this.tabsList)
            }
        },
        // 跳转商品
        spuClick(ids){
            window.open(`#/commodity/productDetails?ids=${ids}`, '_blank');
        }
    }
}
</script>

<style lang="less" scoped>
.home2 {
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    margin-top: 3px;

    // 头部大图片
    >.headImg {
        width: 100%;
        height: 400px;
        display: flex;
        position: relative;

        >.cont {
            width: 1200px;
            height: 400px;
            background: url('../assets/bdbg.jpg');
            background-size: 100% 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            .title {
                >.name {
                    font-size: 64px;
                    font-weight: bold;
                    color: #fff;
                    margin-top: -120px;
                }

                >.btn {
                    width: 323px;
                    height: 28px;
                    border-radius: 16px;
                    background-color: #FFEEB1;
                    font-size: 16px;
                    color: #FA7F4A;
                    text-align: center;
                    line-height: 28px;
                }
            }

        }

        >.zw {
            width: calc((100% - 1200px) / 2);
            height: 400px;
            background-color: #d70d18;
        }

        // tabs
        .tabs {
            width: 1200px;
            height: auto;
            padding: 8px 10px;
            box-sizing: border-box;
            background-color: rgba(255, 255, 255, .2);
            position: absolute;
            bottom: 80px;
            left: calc((100% - 1200px) / 2);
            border-radius: 4px;
            overflow-x: auto;

            >.tab {
                display: flex;
                box-sizing: border-box;
                color: #fff;


                >.son {
                    width: 146px;
                    height: 30px;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                    text-align: center;
                    transition: all .3s;
                    border-radius: 4px;

                    cursor: pointer;
                }
            }

        }
    }

    // tab列表
    .top60 {
        width: 1200px;
        height: auto;
        background-color: #fff;
        position: absolute;
        left: calc((100% - 1200px) / 2);
        top: 350px;
        border-radius: 8px;
        padding-top: 50px;

        >.topTitle {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;

            >.title {
                font-size: 40px;
                font-weight: bold;
                color: #ED3C3C;
            }
        }

        >.list {
            width: 100%;
            height: auto;
            padding: 0 20px;
            box-sizing: border-box;
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            gap: 10px;

            >.son {
                width: 380px;
                height: 150px;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                position: relative;
                margin-bottom: 10px;

                >.img {
                    width: 130px;
                    height: 130px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.r {
                    width: 170px;
                    height: 90px;
                    display: flex;
                    margin-left: 10px;
                    flex-direction: column;
                    justify-content: space-between;

                    >.name {
                        font-size: 16px;
                        color: #333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        /* 添加省略号 */
                        text-overflow: ellipsis;
                    }

                    >.jg {
                        width: 100%;
                        display: flex;

                        >.jiage {
                            color: #FF4242;
                            font-size: 12px;

                            >span {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }

                        >.yishou {
                            font-size: 12px;
                            color: #666;
                            margin-left: 6px;
                        }
                    }
                }

                >.pmbq {
                    position: absolute;
                    top: 0;
                    right: 5px;

                    >div {
                        width: 35px;
                        height: 37;
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        color: #EE8D34;
                        position: absolute;
                        top: 8px;
                        right: 0px;
                    }
                }
            }

            // 分页
            .paging {
                width: 1200px;
                height: 50px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 50px;
            }
        }
    }



}

.tabsAct {
    background-color: #fff;
    color: #FF4242;
    font-weight: bold;
    transition: all .3s;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/* 设置水平滚动条的样式 */
::-webkit-scrollbar {
    height: 6px;
    /* 设置滚动条的高度 */
}

/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* 设置滚动槽的阴影效果 */
    border-radius: 10px;
    /* 设置滚动槽的圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* 设置滚动条滑块的圆角 */
    background: rgba(0, 0, 0, 0.1);
    /* 设置滚动条滑块的背景颜色 */
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    /* 设置滚动条滑块的阴影效果 */
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #d9d9d9;
    /* 设置非活动状态下滚动条滑块的背景颜色 */
}
</style>